import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ThanksPage = props => (
  <div className="text-center">
    <h1 className="text-green-light mt-4">Thanks!</h1>
    <StaticImage
      src="../images/mokkosan.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="もっこうさんイラスト"
    />
    <p className="leading-loose">
      お問い合せを受け付けました。
      <br />
      ありがとうございます。
      <br />
    </p>
    <Link to="/">
      <button className="w-1/2 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-light">
        戻る
      </button>
    </Link>
  </div>
)

export default ThanksPage
